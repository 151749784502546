/* Main click listener */
document.addEventListener('click', function (event) {
  if (
    event.target &&
    event.target.tagName === 'A' &&
    event.target.parentNode &&
    event.target.parentNode.classList &&
    event.target.parentNode.classList.contains('dropdown-submenu')
  ) {
    event.stopPropagation();
    event.preventDefault();
    const submenu = event.target;
    console.log('submenu', submenu);
    submenu.parentNode.parentNode.querySelectorAll('.dropdown-menu').forEach(element => {
      element.classList.remove('show');
    });
    submenu.nextSibling.classList.add('show');
  }
  /* jQuery datatables pagination */
  if (
    event.target &&
    event.target.tagName === 'A' &&
    event.target.classList &&
    event.target.classList.contains('ng2-smart-page-link') &&
    event.target.classList.contains('page-link')
  ) {
    if (!event.currentTarget.querySelector('.modal-dialog')) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  /* jQuery datatables pagination */
});

/* Resize */
window.createResizableColumn = function (col, resizer) {
  // Track the current position of mouse
  let x = 0;
  let w = 0;

  const mouseDownHandler = function (e) {
    // Get the current mouse position
    x = e.clientX;

    // Calculate the current width of column
    const styles = window.getComputedStyle(col);
    w = parseInt(styles.width, 10);

    // Attach listeners for document's events
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
    resizer.classList.add('resizing');
  };

  const mouseMoveHandler = function (e) {
    // Determine how far the mouse has been moved
    const dx = e.clientX - x;

    // Update the width of column
    col.style.width = `${w + dx}px`;
  };

  // When user releases the mouse, remove the existing event listeners
  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
    resizer.classList.remove('resizing');
  };

  resizer.addEventListener('mousedown', mouseDownHandler);
};
/* /Resize */

/* /jQuery Datatables */
